import "./Info.scss";
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";

const CircleDiagram = styled.div`
  position: relative;
  width: 1290px;
  height: 1000px;
  background: #000;
  margin: 0 auto;
  border-radius: 10px;
  padding-bottom: 1rem;
  

  @media (max-width: 600px) {
    width: 460px;
    height: 600px;
  }
`;

const Point = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  margin-top: 3rem;
  border-radius: 50%;
  color: #FCFCFF;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  font-size: 0.7rem;
  overflow: hidden;
  transform: ${(props) =>
    props.isSelected
      ? `translate(-50%, -50%) rotate(${props.angle}deg) translate(300px) rotate(-${props.angle}deg) scale(1.6)`
      : `translate(-50%, -50%) rotate(${props.angle}deg) translate(300px) rotate(-${props.angle}deg)`};
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: ${(props) => (props.isSelected ? 1 : 0.5)};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    z-index: -1;
  }

  @media (max-width: 1000px) {
    margin-top: 1.5rem;
    height: 65px;
    width: 65px;
    font-size: 0.5rem;
    transform: ${(props) =>
      props.isSelected
        ? `translate(-50%, -50%) rotate(${props.angle}deg) translate(200px) rotate(-${props.angle}deg) scale(1.6)`
        : `translate(-50%, -50%) rotate(${props.angle}deg) translate(200px) rotate(-${props.angle}deg)`};
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: ${(props) => (props.isSelected ? 1 : 0.5)};
  }

  @media (max-width: 600px) {
    margin-top: 0;
    height: 50px;
    width: 50px;
    font-size: 0.4rem;
    transform: ${(props) =>
      props.isSelected
        ? `translate(-50%, -50%) rotate(${props.angle}deg) translate(140px) rotate(-${props.angle}deg) scale(1.6)`
        : `translate(-50%, -50%) rotate(${props.angle}deg) translate(140px) rotate(-${props.angle}deg)`};
    transition: transform 0.3s ease, opacity 0.3s ease;
    opacity: ${(props) => (props.isSelected ? 1 : 0.5)};
  }
`;

const CenterText = styled.div`
  position: absolute;
  font-weight: bolder;
  font-size: 3rem;
  color: #FCFCFF;
  border-radius: 10px;
  top: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  padding: .5rem;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    z-index: -1;
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    font-size: 2rem;
  }


  @media (max-width: 600px) {
    font-size: 1.5rem;
  }
`;

const Description = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #FCFCFF;
  width: 300px;
  height: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid black;
  padding: 10px;
  margin-top: 3rem;
  font-size: .9rem;
  display: ${(props) => props.display};

  h2 {
    font-size: 1.8rem;

    @media (max-width: 1000px) {
      font-size: 1.2rem;
    }

    @media (max-width: 600px) {
      font-size: .7rem;
    }
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    z-index: -1;
    border-radius: 10px;
  }

  @media (max-width: 1000px) {
    width: 200px;
    height: 0 auto;
    font-size: .6rem;
    margin-top: 1rem;
  }

  @media (max-width: 600px) {
    width: 130px;
    height: 0 auto;
    padding: 5px;
    font-size: 0.4rem;
    margin-top: 0;
  }
`;

const Line = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 175px;
  height: 6px;
  background: black;
  transform-origin: left center;
  transform: ${(props) => `rotate(${props.angle}deg)`};
  display: ${(props) => (props.show ? "block" : "none")};

  &::after {
    content: "";
    position: absolute;
    top: -5px;
    right: -5px;
    width: 14px;
    height: 14px;
    background: linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    clip-path: polygon(100% 0, 0 0, 100% 100%);
    transform: rotate(45deg);
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet);
    z-index: -1;
  }

  @media (max-width: 1000px) {
    width: 130px;
    &::after {
      top: -2px;
      right: -0px;
      width: 12px;
      height: 12px;
    }
  }

  @media (max-width: 600px) {
    width: 90px;
    &::after {
      top: -2px;
      right: -0px;
      width: 10px;
      height: 10px;
    }
  }
`;

const pointsData = [
  {
    title: "Custom Design",
    description:
      "We will create a unique, custom website with the design that perfectly fits your brand and business needs, rather than being limited to the templates and customization options of a website builder.",
  },
  {
    title: "Technical Expertise",
    description:
      "We have the technical skills to implement complex features, integrations, and custom solutions. We will handle everything from back-end development to front-end design and user experience.",
  },
  {
    title: "Optimized Performance",
    description:
      "We will optimize your website for speed, responsiveness, and SEO, ensuring a smooth and efficient user experience that will help improve your search engine rankings.",
  },
  {
    title: "Scalability",
    description:
      "As your business grows, our team will scale your website to handle increased traffic and functionality, ensuring your site continues to perform well as your needs evolve.",
  },
  {
    title: "Security",
    description:
      "We will implement advanced security measures to protect your website from threats and cyber attacks, and ensure compliance with data protection regulations.",
  },
  {
    title: "Maintenance and Support",
    description:
      "We will provide ongoing maintenance, updates, and support, fixing any issues that arise and keeping your website up-to-date with the latest technologies and trends.",
  },
  // {
  //   title: "Project Management",
  //   description:
  //     "A web development company typically has project managers who ensure your project stays on track, coordinating between different team members and keeping you informed of progress.",
  // },
  {
    title: "Strategic Input",
    description:
      "We will provide strategic input based on our experience with other projects, helping you make informed decisions about your website and app design and functionality.",
  },
  // {
  //   title: "Time-Saving",
  //   description:
  //     "Professionals can get the job done faster because they have the expertise and resources to work efficiently. This allows you to focus on other aspects of your business.",
  // },
  // {
  //   title: "Integration of Latest Technologies",
  //   description:
  //     "Professional developers are always up-to-date with the latest technologies, trends, and tools in web development. They can integrate these into your website, ensuring it remains modern and competitive.",
  // },
  {
    title: "Quality Assurance and Testing",
    description:
      "We conduct thorough testing to ensure your website and app works flawlessly across different devices and browsers.",
  },
  {
    title: "Training and Documentation",
    description:
      "Our team will provide training on how to manage your website and create detailed documentation, making it easier for you to handle minor updates and understand the structure of your website.",
  },
  {
    title: "Brand Consistency",
    description:
      "We will ensure brand consistency across your website, aligning it with your other marketing materials and enhancing your overall brand image.",
  },
  {
    title: "Future-Proofing",
    description:
      "We will build your website and app with future growth and updates in mind, making it easier to add new features, update content, and adapt to future trends.",
  },
  {
    title: "Competitive Advantage",
    description:
      "A professionally developed website and app can give you a competitive edge, showcasing your business as credible and professional, and providing a superior user experience.",
  },
  {
    title: "Conversion Optimization",
    description:
      "We understand the principles of web and app design that lead to higher conversion rates. We will strategically design your website to guide visitors towards taking desired actions, such as making a purchase or signing up for a newsletter.",
  },
  {
    title: "Reliability",
    description:
      "With our team, you have the assurance that your website will be reliable, with minimal downtime or issues, providing a seamless experience for your users.",
  },
];

const Info = () => {
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const points = useMemo(
    () =>
      pointsData.map((point, i) => ({
        ...point,
        angle: i * (360 / pointsData.length),
      })),
    []
  );

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((currentIndex + 1) % points.length);
    }, 8000);
    return () => clearInterval(timer);
  }, [currentIndex, points.length]);

  useEffect(() => {
    setSelectedPoint(points[currentIndex]);
  }, [currentIndex, points]);

  //
  return (
    <CircleDiagram>
      {points.map((point, i) => (
        <Point
          key={i}
          angle={point.angle}
          isSelected={currentIndex === i}
          onClick={() => {
            setCurrentIndex(i);
          }}
        >
          {point.title}
        </Point>
      ))}
      {selectedPoint && (
        <Line angle={selectedPoint.angle} show={selectedPoint ? true : false} />
      )}
      <CenterText>Why Choose Us?</CenterText>
      <Description display={selectedPoint ? "block" : "none"}>
        {selectedPoint && (
          <>
            <h2>{selectedPoint.title}</h2>
            <p>{selectedPoint.description}</p>
          </>
        )}
      </Description>
    </CircleDiagram>
  );
};

export default Info;
