import React from 'react';
import './Footer.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__container--content">
          <div className="footer__container--content-links">
            <a href="/">Home</a>
            <a href="/about">About</a>
            <a href="/contact">Contact</a>
            <a href="/policy">Terms and Policy</a>
          </div>
          <div className="footer__container--content-copyright">
            &copy; {new Date().getFullYear()} ByteVibe Solutions.
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;