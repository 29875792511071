import React from 'react';
import { Link } from 'react-router-dom';
import './Success.scss';

function Success() {
  return (
    <div className='success'>
      <h1 className='success__title'>THANK YOU!</h1>
      <p className='success__text'>Your Order Has Been Placed! Return to <Link to="/">Home</Link> Page.</p>
    </div>
  )
}

export default Success

