import React from 'react';
import './Main.scss';
import logo from '../../assets/images/logo.png';
import { FaMoneyBillTrendUp } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";
import { MdBrowserUpdated } from "react-icons/md";


function Main() {
  
  return (
    <div className='main'>
      <div className='main__top'>
        <div className='main__top--image'>
          <img className='main__top--image-img' src={logo} alt='Penggy' />
        </div>
        <div className='main__top--title'>
          <h1 className='main__top--title-header'>Welcome to <span className='headers'>ByteVibe Solutions</span></h1>
          <p className='main__top--title-text'>From Local to Global: Skyrocket Your Brand by Going Online with a simple Solution! The ByteVibe Solution</p>
        </div>
      </div>
      <div className='main__bottom'>
        <div className='main__bottom--container'>
          <div className='main__bottom--container-header'><FaMoneyBillTrendUp /></div>
          <p className='main__bottom--container-text'>Monthly payments provide convenience by allowing customers to pay for goods and services in smaller, manageable increments rather than paying a lump sum upfront.</p>
        </div>
        <div className='main__bottom--container'>
          <div className='main__bottom--container-header'><CgWebsite /></div>
          <p className='main__bottom--container-text'>We Build professional and visually appealing website the way You want us to that reflects your brand.</p>
        </div>
        <div className='main__bottom--container'>
          <div className='main__bottom--container-header'><MdBrowserUpdated /></div>           
            <p className='main__bottom--container-text'>Update your website or app whenever you desire with our services at no additional cost!</p>
          </div>
      </div>
    </div>
  )
}

export default Main;