import 'bootstrap/dist/css/bootstrap.min.css';
import NavbarComponent from './components/navbar/Navbar';
import './App.scss';
import { Container } from 'react-bootstrap';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Store from './pages/Store/Store';
import Success from './pages/Success/Success';
import Cancel from './pages/Cancel/Cancel';
import CartProvider from './CartContext';
import Footer from './components/footer/Footer';
import About from './pages/about/About';
import Contact from './pages/contact/Contact';
import Policy from './pages/policy/Policy';

function App() {
  return (
    <CartProvider>
      <div className="App">
        <Container>
          <NavbarComponent></NavbarComponent>
          <BrowserRouter>
            <Routes>
              <Route index element={<Store /> } />
              <Route path="/success" element={<Success /> } />
              <Route path="/cancel" element={<Cancel /> } />
              <Route path="/about" element={<About /> } />
              <Route path="/contact" element={<Contact /> } />
              <Route path="/policy" element={<Policy /> } />
            </Routes>
          </BrowserRouter>
          <Footer />
        </Container>
    </div>
    </CartProvider>
  );
}

export default App;
