import React, { useContext } from 'react';
import { CartContext } from '../../CartContext';
import Button from 'react-bootstrap/Button';
import { getProductData } from '../../productStore';

function CartProduct(props) {
  const cart = useContext(CartContext);
  const id = props.id;
  const quantity = props.quantity;
  const productData = getProductData(id);

  const trashIconSvg = (
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#f72585" className="bi bi-trash-fill" viewBox="0 0 16 16">
          <path d="M2.5 1a1 1 0 0 0-1 1v1a1 1 0 0 0 1 1H3v9a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V4h.5a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H10a1 1 0 0 0-1-1H7a1 1 0 0 0-1 1H2.5zm3 4a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1H8a.5.5 0 0 1-.5-.5zm-2 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1H6a.5.5 0 0 1-.5-.5zm2 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1H8a.5.5 0 0 1-.5-.5z"/>
      </svg>
  );

  return (
      <>
          <h3>{productData.name}</h3>
          <p>{quantity} total</p>
          <p>${ (quantity * productData.price).toFixed(2) }</p>
          <Button className='button' size="sm" onClick={() => cart.deleteFromCart(id)}>
              {trashIconSvg}
          </Button>
          <hr></hr>
      </>
  )
}

export default CartProduct;


