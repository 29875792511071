import React, { useState, useContext } from 'react';
import { Button, Navbar, Modal, Nav} from 'react-bootstrap';
import { CartContext } from '../../CartContext';
import CartProduct from '../cartProduct/CartProduct';
import './Navbar.scss';
import { ReactComponent as CartIcon } from 'bootstrap-icons/icons/cart.svg';
import logo from '../../assets/images/logo.png';
import { getProductData } from '../../productStore';

function NavbarComponent() {
    const cart = useContext(CartContext);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const checkout = async () => {
        let mode = 'payment';
        for (let item of cart.items) {
            let productData = getProductData(item.id);
            if (productData.name.endsWith('Monthly Plan')) {
                mode = 'subscription';
                break;
            }
        }
    
        let endpoint;
        if (mode === 'payment') {
            endpoint = 'http://localhost:4000/checkout-payment';
        } else if (mode === 'subscription') {
            endpoint = 'http://localhost:4000/checkout-subscription';
        } else {
            throw new Error('Invalid checkout mode');
        }

        // let endpoint;
        // if (mode === 'payment') {
        //     endpoint = 'https://bytevibesolutions.com/checkout-payment';
        // } else if (mode === 'subscription') {
        //     endpoint = 'https://bytevibesolutions.com/checkout-subscription';
        // } else {
        //     throw new Error('Invalid checkout mode');
        // }
    
        await fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ items: cart.items })
        }).then((response) => {
            return response.json();
        }).then((response) => {
            if(response.url) {
                window.location.assign(response.url);
            }
        }).catch(error => console.error('Error:', error));
    }

    const productCount = cart.items.reduce((sum, product) => sum + product.quantity, 0);

    return (
        <>
            <Navbar expand="sm" bg="dark" variant="dark" className='navbar'>
                <Navbar.Brand href="/" className='navbar__header'>
                    <img src={logo} alt="Logo" className='navbar__header--logo' /> 
                </Navbar.Brand>
                <Navbar.Toggle className='navbar__menu navbar__menu navbar__menu-toggle' />
                <Navbar.Collapse className="navbar__nav">
                    <Nav className='navbar__nav--item'>
                        <Nav.Link href="/" className='navbar__nav--item-items'>Home</Nav.Link>
                        <Nav.Link href="/about" className='navbar__nav--item-items'>About</Nav.Link>
                        <Nav.Link href="/contact" className='navbar__nav--item-items'>Contact</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
                <Navbar.Collapse className="navbar__button">
                    <Button onClick={handleShow} className='navbar__button--button'>
                        <CartIcon fill="#440e67" className='cart-icon' /> <span className='product-count'>{productCount}</span>
                    </Button>
                </Navbar.Collapse>
            </Navbar>
            <Modal className='modal' show={show} onHide={handleClose}>
                <Modal.Header className='modal__header custom-close-button' closeButton>
                    <Modal.Title className='modal__header--title'>Cart</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal__body'>
                    {productCount > 0 ?
                    <>
                        <p>Items in your cart:</p>
                        {cart.items.map((currentProduct, index) => (
                            <CartProduct className='modal__body--product' key={index} id={currentProduct.id} quantity={currentProduct.quantity}></CartProduct>
                        ))}
    
                        <h1 className='modal__body--header'>Total: ${cart.getTotalCost().toFixed(2)}</h1>
    
                        <Button className='modal__body--button' variant="success" onClick={checkout}>
                            Purchase Items!
                        </Button>
                    </>
                    :
                    <h1 className='modal__body--text'>Your cart is empty</h1>
                    }
                </Modal.Body>
                <Modal.Footer className='modal__footer'>
                <Button className='modal__footer--button' variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button className='modal__footer--button' variant="primary" onClick={checkout}>
                    Checkout
                </Button>
            </Modal.Footer>
            </Modal>
        </>
      )
    }

    export default NavbarComponent;