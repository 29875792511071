import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { productsArray } from '../../productStore';
import ProductCard from '../../components/productCard/ProductCard';
import Main from '../../components/main/Main';
import Info from '../../components/info/Info';
import './Store.scss';
import Slider from '../../components/slider/Slider';
import Steps from '../../components/steps/Steps';

function Store() {
  return (
    <>
        <Row xs={1} md={3} className="store">
            <Main />
            <Info />
            <Steps />
            {productsArray.map((product, index) => (
                <Col align="center" key={index}>
                    <ProductCard product={product} showMonthly={index > 2} />
                </Col>
            ))}
             <Slider />
        </Row>
    </>
  )
}

export default Store