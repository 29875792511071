const productsArray = [
    {
        type: "product",
        id: "price_1OrOV5LtU415gQH3E3hEM4OC",
        name: 'Responsive Web Design',
        price: 499.99,
        description: 'Responsive Web Design is about using HTML and CSS to automatically resize, hide, shrink, or enlarge, a website, to make it look good on all devices (desktops, tablets, and phones).'
    },
    {
        type: "product",
        id: "price_1OrOTULtU415gQH3xFXgbIYC",
        name: 'Functional Web Design',
        price: 599.99,
        description: 'Functional Web Design is about using HTML, CSS, and JavaScript to create a website that is easy to navigate and provides a positive user experience.'
    },
    {
        type: "product",
        id: "price_1OrOL7LtU415gQH3A1DJUCQ2",
        name: 'APP Design',
        price: 699.99,
        description: 'App design combines the user interface (UI) and user experience (UX) to create a digital product that is both functional and visually appealing.'
    },
    {
        type: "product",
        id: "price_1OrOYfLtU415gQH3KW2zYmqO",
        name: 'Responsive Monthly Plan',
        price: 99,
        description: 'After purchasing the Responsive Web Design, you have to subscribe to the monthly plan to have to Website live for your clients.'
    },
    {
        type: "product",
        id: "price_1OrOaBLtU415gQH33jeT1hqX",
        name: 'Functional Monthly Plan',
        price: 119,
        description: 'After purchasing the Functional Web Design, you have to subscribe to the monthly plan to have to Website live for your clients.'
    },
    {
        type: "product",
        id: "price_1OrObsLtU415gQH3ya1Vx9XH",
        name: 'App Monthly Plan',
        price: 149,
        description: 'After purchasing the App Design, you have to subscribe to the monthly plan to have to Website live for your clients.'
    }
];

function getProductData(id) {
    let productData = productsArray.find(product => product.id === id);

    if (productData === undefined) {
        console.log('Product not found for ID: ' + id);
        return undefined;
    }

    return productData;
}

export { productsArray, getProductData };