import React, { useContext, useState } from 'react';
import { Card, Button, Form, Row, Col, Modal } from 'react-bootstrap';
import { CartContext } from '../../CartContext';
import './ProductCard.scss';

function ProductCard(props) {
    const product = props.product;
    const cart = useContext(CartContext);
    const productQuantity = cart.getProductQuantity(product.id);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    console.log(cart.items);
    return (
        <>
            <Card className='card'>
                <Card.Body className='card__body'>
                    <Card.Title className='card__body--title'>{product.name}</Card.Title>
                    <Card.Text className='card__body--price'>From ${product.price}{props.showMonthly ? "/Mth" : ""}</Card.Text>
                    { productQuantity > 0 ?
                    <>
                        <Form as={Row}>
                            <Form.Label className="label" column="true" sm="6">In Cart: {productQuantity}</Form.Label>
                            <Col sm="6">
                                <Button className='button' sm="6" onClick={() => cart.addOneToCart(product.id)}>+</Button>
                                <Button className='button' sm="6" onClick={() => cart.removeOneFromCart(product.id)}>-</Button>
                            </Col>
                        </Form>
                        <Button className='button' variant="danger" onClick={() => cart.deleteFromCart(product.id)}>Remove from Cart</Button>
                    </>
                    :
                    <>
                        <Button variant="primary" onClick={() => cart.addOneToCart(product.id)} className='my-2'>Add to Cart</Button>
                        <Button variant="info" onClick={handleShow} className='my-2'>View Product</Button>
                    </>
                    }
                </Card.Body>
            </Card>

            <Modal className='modal' show={show} onHide={handleClose}>
                <Modal.Header closeButton className='modal__header-close'>
                    <Modal.Title className='modal__header-close--name'>{product.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='modal__header-close--description'>
                    {product.description}
                </Modal.Body>
                <Modal.Footer className='modal__header-close--footer'>
                    <Button variant="secondary" onClick={handleClose} className='modal__header-close--footer-button'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ProductCard