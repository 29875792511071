import React from 'react';
import { Link } from 'react-router-dom';
import './Steps.scss';

function Steps() {
  return (
    <div className='steps'>
        <div className='steps__top'>
            <h1 className='steps__top--title'>How It Works</h1>
            <p className='steps__top--text'>Explained in 3 Easy Steps!</p>
        </div>
        <div className='steps__bottom'>
            <div className='steps__bottom--container'>
                <h2 className='steps__bottom--container-title'>Step One</h2>
                <p className='steps__bottom--container-text'><Link to="/contact">Contact</Link> Us and share your vision for a project you would like us to develop. You can reach us by phone or email.</p>
            </div>
            <div className='steps__bottom--container'>
                <h2 className='steps__bottom--container-title'>Step Two</h2>
                <p className='steps__bottom--container-text'>Purchasing your option down below. Upon contacting us, we will guide you towards selecting the right option that aligns with the needs of your brand and company.</p>
            </div>
            <div className='steps__bottom--container'>
                <h2 className='steps__bottom--container-title'>Step Three</h2>
                <p className='steps__bottom--container-text'>Upon completion of the construction of your website and/or application, we kindly request that you notify us of your satisfaction and any potential updates or modifications you may require. We will be pleased to enroll you in our Monthly Plan accordingly.</p>
            </div>
        </div>
    </div>
  
  )
}

export default Steps


  // <div className='main__bottom'>
    //     <div className='main__bottom--container'>
    //       <p className='main__bottom--container-text'>Monthly payments provide convenience by allowing customers to pay for goods and services in smaller, manageable increments rather than paying a lump sum upfront.</p>
    //     </div>
    //     <div className='main__bottom--container'>
    //       <p className='main__bottom--container-text'>We Build professional and visually appealing website the way You want us to that reflects your brand.</p>
    //     </div>
    //     <div className='main__bottom--container'>
    //         <p className='main__bottom--container-text'>Update your website or app whenever you desire with our services at no additional cost!</p>
    //       </div>
    //   </div>