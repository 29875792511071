import React from 'react'
import { Link } from 'react-router-dom';
import './Cancel.scss';

function Cancel() {
  return (
    <div className='cancel'>
      <h1 className='cancel__title'>Your Order Has Been Cancelled! Return to <Link to="/">Home</Link> Page.</h1>
    </div>
  )
}

export default Cancel