import React from "react";
import "./About.scss";

function About() {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        Welcome to our ByteVibe Solutions! We specialize in developing functional,
        responsive websites and applications.
      </p>
      <p>
        Our team of experienced developers is dedicated to creating digital
        solutions that meet our clients' needs. We understand the importance of
        a strong online presence in today's digital age, and we're here to help
        you achieve that.
      </p>
      <p>
        Whether you need a responsive website that looks great on any device, or
        a custom application to streamline your business processes, we've got
        you covered. We use the latest technologies and best practices to ensure
        that our products are not only functional, but also reliable and easy to
        use.
      </p>
      <p>
        We're passionate about what we do, and we're committed to delivering
        high-quality products that exceed your expectations. Contact us today to
        find out how we can help you achieve your digital goals.
      </p>
    </div>
  );
}

export default About;
