import React from 'react';
import Slider from 'react-slick';
import one from '../../assets/images/1.webp';
import two from '../../assets/images/2.png';
import three from '../../assets/images/3.png';
import four from '../../assets/images/4.jpg';
import five from '../../assets/images/5.jpg';
import six from '../../assets/images/6.png';
import seven from '../../assets/images/7.jpg';
import eight from '../../assets/images/8.avif';
import nine from '../../assets/images/9.jpeg';  
import ten from '../../assets/images/10.jpg';
import twelve from '../../assets/images/12.jpeg';
import thirteen from '../../assets/images/13.jpg';
import fourteen from '../../assets/images/14.avif';
import fifteen from '../../assets/images/15.png';
import sixteen from '../../assets/images/16.jpg';
import twentyOne from '../../assets/images/21.jpg';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import './Slider.scss';

function Sliders() {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '60px',
        autoplay: true,
        autoplaySpeed: 2000,
        cssEase: 'linear'
      };

  return (
    <div className='slider'>
        <div className='slider__bottom'>
            <Slider {...settings} className="slick-slider ">
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={one} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={two} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={three} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={four} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={five} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={six} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={seven} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={eight} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={nine} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={ten} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={twelve} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={thirteen} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={fourteen} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={fifteen} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={sixteen} alt='penggy' />
            </div>
            <div className='slider__bottom--image'>
                <img className='slider__bottom--image-img' src={twentyOne} alt='penggy' />
            </div>
            </Slider>
        </div>
    </div>
  )
}

export default Sliders